import type { Experiments, IHttpClient } from '@wix/yoshi-flow-editor';
import collectionsApi from '../api/collections-api';
import { Status, WidgetsName } from '../consts/collectionsConsts';
import type AppStateManager from '../viewerScriptHelpers/AppStateManager';
import {
  LocalStorageCachedCapsule,
  WixStorageStrategy,
  DataCapsule,
} from 'data-capsule';
import { collectionCreated } from '@wix/bi-logger-photo-ugc/v2';
import { findCoverSrcById } from './utils';
import _ from 'lodash';

type LoveServiceProps = {
  httpClient: IHttpClient;
  setWidgetsProps: (widgets: string[], props: any) => void;
  galleryId: string;
  appStateManager: AppStateManager;
  log?: (func: Function, additionalBiParams: any) => void;
  t: (key: string) => string;
  instance: string;
  metaSiteId: string;
  isViewer: boolean;
  experiments: Experiments;
  albumData?: AlbumData;
  getAuthHeader: () => string;
  firstItem?: MediaItem | false;
};

export type LoveActions = Pick<
  LoveService,
  | 'init'
  | 'toggleLove'
  | 'fetchCollectionItemsLoveData'
  | 'switchCollectionAndLove'
  | 'fetchItemCollections'
  | 'createCollectionAndLove'
>;

export default class LoveService {
  loveData: LoveData;
  currentCollectionId: string;
  collectionsApi: ReturnType<typeof collectionsApi>;
  collections: ItemMediaCollection[];
  isItemInCollectionMap: {
    [itemId: string]: { [collectionId: string]: boolean };
  };
  setWidgetsProps: (widgets: string[], props: any) => void;
  galleryId: string;
  appStateManager: AppStateManager;
  initiated: boolean;
  log?: (func: Function, additionalBiParams: any) => void;
  t: (key: string, options?: any) => string;
  capsule?: DataCapsule | false;
  experiments: Experiments;
  albumData?: AlbumData;
  shouldTriggerCommentsTooltip: boolean | undefined;
  instance: string;
  isViewer: boolean;
  firstItem?: MediaItem | false;
  getAuthHeader: () => string;

  constructor(props: LoveServiceProps) {
    this.galleryId = props.galleryId;
    this.loveData = {};
    this.currentCollectionId = '';
    this.collectionsApi = collectionsApi(props.httpClient);
    this.collections = [];
    this.isItemInCollectionMap = {};
    this.initiated = false;
    this.fetchCollectionItemsLoveData =
      this.fetchCollectionItemsLoveData.bind(this);
    this.albumData = props.albumData;
    this.experiments = props.experiments;
    this.setWidgetsProps = props.setWidgetsProps;
    this.switchCollection = this.switchCollection.bind(this);
    this.switchCollectionAndLove = this.switchCollectionAndLove.bind(this);
    this.fetchItemCollections = this.fetchItemCollections.bind(this);
    this.toggleLove = this.toggleLove.bind(this);
    this.toggleLoveAction = this.toggleLoveAction.bind(this);
    this.createCollectionAndLove = this.createCollectionAndLove.bind(this);
    this.appStateManager = props.appStateManager;
    this.t = props.t;
    this.log = props.log;
    this.instance = props.instance;
    this.isViewer = props.isViewer;
    this.firstItem = props.firstItem;
    // initialized here because we want to import it dynamically
    // cannot make capsule calls outside of viewer because of domain mapping issues
    this.init = this.init.bind(this);
    this.getAuthHeader = props.getAuthHeader;

    this.setWidgetsProps([WidgetsName.Gallery], {
      loveActions: {
        init: this.init,
        toggleLove: this.toggleLove,
        fetchItemCollections: this.fetchItemCollections,
        fetchLoveData: this.fetchCollectionItemsLoveData,
        switchCollectionAndLove: this.switchCollectionAndLove,
        createCollectionAndLove: this.createCollectionAndLove,
      },
    });
  }

  init(itemToLove?: string) {
    // user has to be loggedIn in order to use this capusule
    this.instance = this.getAuthHeader();
    this.capsule =
      this.isViewer &&
      LocalStorageCachedCapsule({
        // @ts-expect-error
        remoteStrategy: new WixStorageStrategy({
          signedInstance: this.instance,
        }),
        namespace: 'media-collections',
        scope: this.albumData?.settings.metaSiteId,
      });
    // we only need collection details so we prefer 'getItemCollections' over 'getCollections'
    // since it only gets the required details
    this.initDefaultCollection(this.firstItem).then(async () => {
      await this.fetchCollectionItemsLoveData(this.currentCollectionId);
      itemToLove && (await this.toggleLove(itemToLove));
    });
    this.shouldTriggerTooltip().then(
      (shouldShow) => (this.shouldTriggerCommentsTooltip = shouldShow),
    );
  }

  async getDefaultCollection(item?: MediaItem | false) {
    let initialCollection;
    try {
      if (this.capsule) {
        initialCollection = JSON.parse(
          await this.capsule.getItem('defaultCollection'),
        );
      }
    } catch (e) {
      console.log('No default list');
    }
    item && (await this.fetchItemCollections(item.id));
    if (!initialCollection && item) {
      const filteredCollections = this.collections.filter(
        (collection: any) => !collection.isLocked,
      );
      const firstCollection =
        filteredCollections.length > 0 && filteredCollections[0];

      if (firstCollection) {
        initialCollection = {
          id: firstCollection.mediaCollectionId,
          name: firstCollection.name,
        };
      }
    }
    return initialCollection;
  }

  async initDefaultCollection(item?: MediaItem | false) {
    const initialCollection = await this.getDefaultCollection(item);
    if (initialCollection) {
      this.currentCollectionId = initialCollection.id;
      this.setWidgetsProps([WidgetsName.Gallery], {
        currentCollectionName: initialCollection.name,
      });
      try {
        this.capsule &&
          this.capsule.setItem(
            'defaultCollection',
            JSON.stringify(initialCollection),
          );
      } catch (e) {
        console.error('Failed to set default list');
      }
    }
  }

  updateCollectionData = (isLoved: boolean, itemId: string) => {
    const collectionIdx = this.collections.findIndex(
      (collection) => collection.mediaCollectionId === this.currentCollectionId,
    );
    const collection = this.collections[collectionIdx];
    if (!isLoved) {
      if (collection.totalItemsCount !== undefined) {
        collection.totalItemsCount++;
      }
      if (!collection?.firstItem) {
        const cover = findCoverSrcById(this.albumData?.sets.gallery, itemId);
        collection.firstItem = cover;
      }
    } else {
      if (collection.totalItemsCount !== undefined) {
        const isCover = collection.totalItemsCount === 1;
        if (isCover && collection.firstItem) {
          delete collection.firstItem;
        }
        collection.totalItemsCount--;
      }
    }
  };

  triggerToastAndRevertChanges = (
    itemId: string,
    result: boolean,
    isLoved: boolean,
  ) => {
    const collectionIdx = this.collections.findIndex(
      (collection) => collection.mediaCollectionId === this.currentCollectionId,
    );
    this.loveData[itemId].isLoved = !result;
    this.isItemInCollectionMap[itemId][this.currentCollectionId] = !result;
    const collection = this.collections[collectionIdx];
    if (!isLoved) {
      if (collection && collection.totalItemsCount) {
        collection.totalItemsCount--;
        collection.totalItemsCount === 0 && delete collection.firstItem;
      }
    } else {
      collection && collection.totalItemsCount && collection.totalItemsCount++;
    }
    this.setWidgetsProps([WidgetsName.Gallery], {
      itemsLoveData: this.loveData,
      controllerToast: {
        content: this.t('COLLECTION_EDIT_INFO_TOAST_FAILURE'),
      },
    });
  };

  private toggleLoveAction = _.debounce(
    async (itemId: string, collectionId?: string) => {
      let result = false;
      const shouldJoin = this.shouldJoin(
        collectionId || this.currentCollectionId,
      );

      if (shouldJoin) {
        try {
          await this.collectionsApi.joinCollection(
            collectionId || this.currentCollectionId,
          );
          this.updateCollectionStatus(this.currentCollectionId, Status.MEMBER);
        } catch (e) {
          this.setWidgetsProps([WidgetsName.Gallery], {
            controllerToast: {
              content: this.t('COLLECTION_JOIN_TOAST_FAILURE'),
            },
          });
        }
      }

      const isLoved = this.loveData[itemId]?.isLoved;

      if (!isLoved) {
        result = true;
        this.collectionsApi
          .addItems(this.currentCollectionId, [
            {
              itemId,
              galleryId: this.galleryId,
              orderIndex: Date.now(),
            },
          ])
          .catch((e) => {
            this.triggerToastAndRevertChanges(itemId, result, isLoved);
          });
      } else {
        this.collectionsApi
          .removeItems(this.currentCollectionId, [itemId])
          .catch((e) => {
            this.triggerToastAndRevertChanges(itemId, result, isLoved);
          });
      }

      if (this.loveData[itemId]) {
        this.loveData[itemId].isLoved = result;
      } else {
        this.loveData[itemId] = { isLoved: result };
      }

      if (this.isItemInCollectionMap[itemId]) {
        this.isItemInCollectionMap[itemId][this.currentCollectionId] = result;
        this.updateCollectionData(isLoved, itemId);
      }

      this.appStateManager.setPimpleVisibility(true);
      this.setWidgetsProps([WidgetsName.Gallery], {
        itemsLoveData: this.loveData,
      });
    },
    300,
  );

  shouldTriggerTooltip = async () => {
    let shouldShowTooltip = false;
    if (this.capsule) {
      try {
        const alreadyShown = await this.capsule.getItem(
          'alreadyShownCommentsTooltip_' + this.albumData?.settings.id,
        );
        shouldShowTooltip = !alreadyShown;
      } catch (e) {
        this.capsule.setItem(
          'alreadyShownCommentsTooltip_' + this.albumData?.settings.id,
          true,
        );
        shouldShowTooltip = true;
      }
    }
    return shouldShowTooltip;
  };

  async toggleLove(id: string) {
    if (this.appStateManager.state.user.loggedIn) {
      if (this.shouldTriggerCommentsTooltip !== false) {
        this.setWidgetsProps([WidgetsName.HeartIcon], {
          shouldTriggerCommentsTooltip: this.shouldTriggerCommentsTooltip,
        });
      }
      this.toggleLoveAction(id);
    } else {
      try {
        await this.appStateManager.login('add image');
        this.currentCollectionId && this.toggleLoveAction(id);
      } catch (e: any) {
        console.error('Not logged in', e);
      }
    }
  }

  switchCollection(
    collectionId: string,
    itemId: string,
    collectionName?: string,
  ) {
    if (collectionId) {
      const newCollection = {
        id: collectionId,
        name:
          collectionName ||
          this.collections.find(
            (collection) => collection.mediaCollectionId === collectionId,
          )?.name,
      };
      this.currentCollectionId = collectionId;
      this.setWidgetsProps([WidgetsName.Gallery], {
        currentCollectionName: newCollection.name,
      });
      try {
        this.capsule &&
          this.capsule.setItem(
            'defaultCollection',
            JSON.stringify(newCollection),
          );
      } catch (e) {
        console.error('Failed to set default list');
      }
    }
  }
  shouldJoin = (collectionId: string) => {
    const isMemberInCollection = this.collections.find((collection) => {
      if (collection.mediaCollectionId === collectionId) {
        if (collection.role === 'MEMBER' || collection.role === 'ADMIN') {
          return true;
        } else {
          return false;
        }
      }
    });
    return !isMemberInCollection;
  };

  updateCollectionStatus(collectionId: string, status: Status) {
    const collectionIdx = this.collections.findIndex(
      (collection) => collection.mediaCollectionId === collectionId,
    );
    this.collections[collectionIdx].role = status;
  }
  async switchCollectionAndLove(
    collectionId: string,
    itemId: string,
    collectionName?: string,
    afterLogin?: boolean,
  ) {
    if (afterLogin) {
      await this.fetchItemCollections(itemId);
    }
    const collectionChanged = collectionId !== this.currentCollectionId;
    this.switchCollection(collectionId, itemId, collectionName);
    collectionChanged &&
      (await this.fetchCollectionItemsLoveData(collectionId));
    this.toggleLoveAction(itemId, collectionId);
  }

  async createCollectionAndLove(
    itemId: string,
    createParams: string,
    biDefaultParamsForLove: object,
  ) {
    const parsedCreateParams = JSON.parse(createParams) as {
      name: string;
      privacySettings?: PrivacySettings;
      description?: string;
    };
    const { name, privacySettings, description } = parsedCreateParams;
    const { mediaCollectionId } =
      await this.collectionsApi.createCollectionOnServer({
        name,
        privacySettings,
        description,
        items: [
          {
            itemId,
            galleryId: this.galleryId,
            orderIndex: Date.now(),
          },
        ],
      });
    if (mediaCollectionId) {
      // Add collection to already fetched lists
      const collectionCover = findCoverSrcById(
        this.albumData?.sets.gallery,
        itemId,
      );
      this.loveData = {
        [itemId]: {
          isLoved: true,
        },
      };
      this.collections.push({
        mediaCollectionId,
        name,
        role: Status.ADMIN,
        isItemInCollection: true,
        isLocked: false,
        totalItemsCount: 1,
        creatorNickname: 'Me',
        firstItem: collectionCover,
      });

      Object.keys(this.isItemInCollectionMap).forEach((id) => {
        this.isItemInCollectionMap[id][mediaCollectionId] = id === itemId;
      });
      // item was already added upon craetion
      this.switchCollection(mediaCollectionId, itemId, name);
      this.setWidgetsProps([WidgetsName.Gallery], {
        currentItemCollections: this.getItemCollections(itemId),
        itemsLoveData: this.loveData,
        controllerToast: {
          content: this.t('GALLERY_ADD_TO_LIST_MODAL_TOAST_CREATED', {
            listName: name,
          }),
        },
      });
      this.appStateManager.setPimpleVisibility(true);
      this.log &&
        this.log(collectionCreated, {
          ...biDefaultParamsForLove,
          params: createParams,
          collection_id: mediaCollectionId,
        });
    }
  }

  async fetchCollectionItemsLoveData(collectionId: string) {
    if (collectionId) {
      this.currentCollectionId = collectionId;
      const collectionItemIds = await this.collectionsApi.getCollectionItemIds(
        collectionId,
        '',
      );
      this.loveData = {};
      collectionItemIds.forEach((id?: string) => {
        if (id) {
          this.loveData[id] = { isLoved: true };
        }
      });
    }
    this.setWidgetsProps([WidgetsName.Gallery], {
      itemsLoveData: this.loveData,
    });
    return this.loveData;
  }

  getItemCollections = (itemId: string) => {
    const filteredCollections = this.collections.filter(
      (collection: any) => !collection.isLocked,
    );
    return filteredCollections.map((collection) => {
      const collectionId = collection.mediaCollectionId;
      collection.isItemInCollection =
        this.isItemInCollectionMap[itemId][collectionId];
      return collection;
    });
  };

  async fetchItemCollections(itemId: string) {
    if (!this.isItemInCollectionMap[itemId]) {
      const { itemInCollections } =
        await this.collectionsApi.getItemCollections(itemId, this.galleryId);
      this.collections = itemInCollections;
      itemInCollections.forEach((collection) => {
        this.isItemInCollectionMap[itemId] = {
          ...this.isItemInCollectionMap[itemId],
          [collection.mediaCollectionId]: collection.isItemInCollection,
        };
      });
    }
    this.setWidgetsProps([WidgetsName.Gallery], {
      currentItemCollections: this.getItemCollections(itemId),
    });
  }
}
