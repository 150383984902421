export const initStoreManager = ({
  wixCodeApi,
  storeManager,
  compId,
  setProps,
}: {
  wixCodeApi: any;
  storeManager: any;
  compId: string;
  setProps: any;
}) => {
  const isSSR = wixCodeApi.window.rendering.env === 'backend';
  storeManager.initCartApiIfNeeded(isSSR);
  if (!isSSR) {
    storeManager.initTranslations(setProps);
  }
  const storeApi = storeManager.getStoreApi(wixCodeApi, compId);
  const consentPolicy = storeManager.getConsentPolicy(wixCodeApi);
  storeManager.addStoreWidget({ setProps, compId, type: 'GALLERY' });
  return { storeApi, consentPolicy };
};
