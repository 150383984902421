import { ALBUMS, MEDIA_COLLECTIONS } from '../../../consts/photographyConsts';
import { LOVE_BUTTON_ACTION } from '../../../consts/collectionsConsts';
import _ from 'lodash';

export const isValidAlbumForEnablingStore = (
  albumsSettings: AlbumSettingsExtended,
) => {
  const { template, oldVersionTemplate = {} } = albumsSettings;
  return (
    !albumsSettings.migratedFrom &&
    template &&
    template.version > 2 &&
    (_.isEmpty(oldVersionTemplate) ||
      (oldVersionTemplate.version && oldVersionTemplate.version > 2))
  );
};

export const initProGalleryStore = (
  commonViewerScript: any,
  albumsSettings: AlbumSettingsExtended,
  setSettings: any,
) => {
  const proGalleryStore: any = commonViewerScript.getPgStore();
  proGalleryStore.galleryId = albumsSettings.galleryId;
  if (albumsSettings?.loveButtonAction === LOVE_BUTTON_ACTION.FAVORITE_LISTS) {
    proGalleryStore.getItemsLoveDataIfNeeded = () => {};
  }
  albumsSettings.ignoreWatermark =
    !isValidAlbumForEnablingStore(albumsSettings);
  setSettings(albumsSettings);
};

export const getInitPromise = async (
  commonViewerScript: any,
  storeManager: any,
  albumsSettings: any,
) => {
  const { connectedProviders, additionalProviderParams } = storeManager
    ? await storeManager.initStoreWixCodeApi(
        albumsSettings,
        albumsSettings.styleParams, // TODO: remove
        ALBUMS.ALBUMS_APP_DEF_ID,
      )
    : { connectedProviders: undefined, additionalProviderParams: undefined };
  await Promise.all([
    commonViewerScript.getBlueprintReadyPromise(),
    commonViewerScript.getDirectFullscreenReadyPromise(),
  ]);
  return {
    connectedProviders,
    additionalProviderParams,
    blueprint: commonViewerScript.getBlueprint(),
  };
};

export const getSectionUrlFunc = (
  context: any,
  additionalProviderParams: any,
) => {
  const getSectionUrl = (sectionId: string) => {
    const queryParams = `?srcId=${additionalProviderParams.srcId}&appDefId=${ALBUMS.ALBUMS_APP_DEF_ID}`;
    const section = context
      .getContext()
      .scopedGlobalSdkApis.site.getSectionUrl({
        sectionId,
        appDefinitionId: MEDIA_COLLECTIONS.MEDIA_COLLECTIONS_APP_DEFINITION_ID,
      });
    section.url = section.url + queryParams;
    return section;
  };
  return getSectionUrl;
};
