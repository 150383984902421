import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { CommonViewerScript } from '@wix/common-pro-gallery-worker-wrapper';
import { ART_STORE, ALBUMS, PRO_GALLERY } from '../../consts/photographyConsts';
import {
  GALLERY_TYPE,
  GALLERY_TYPES,
  getGalleryType,
} from '../../common/helpers/galleryTypes';
import { getInitialData } from './helpers/galleryInitialDataFetcher';
import { initStoreManager } from './helpers/storeHelper';
import { createNavigator } from '../../services/navigation';

import {
  initProGalleryStore,
  getInitPromise,
  getSectionUrlFunc,
} from './helpers/galleryViewerscriptHelper';
import collectionsApi from '../../api/collections-api';
import { LOVE_BUTTON_ACTION } from '../../consts/collectionsConsts';
import { MEMBERS_AREA } from '@wix/app-definition-ids';
import { getBaseUrl } from '../../common/utils';
import { experimentsWrapper } from '@wix/photography-client-lib';
import {
  isNotListMember,
  isNotLoggedIn,
} from '../../common/helpers/permissions';
import type GalleryWixCodeApiManager from '../../common/WixCode/GalleryWixCodeApiManager';
import type DataFetcher from '../../viewerScriptHelpers/DataFetcher';
import type AppStateManager from '../../viewerScriptHelpers/AppStateManager';
import type CommentsManager from '../../common/CommentsManager';
import {
  SetItemsFetcher,
  CollectionItemsFetcher,
} from './Widget/ItemsFetchers';
import { getActions } from '../../viewerScriptHelpers/actions';
import LoveService from '../../common/LoveService';

const createController: CreateControllerFn = async (props) => {
  const { flowAPI, controllerConfig: config, appData } = props;
  const { setProps, appParams, platformAPIs } = flowAPI.controllerConfig;
  const { type, wixCodeApi, compId } = config;
  const {
    context,
    storeManager,
    dataFetcher,
    galleryWixCodeApiManager,
    commentsManager,
    appStateManager,
  } = appData as {
    storeManager: any;
    context: any;
    galleryWixCodeApiManager: GalleryWixCodeApiManager;
    dataFetcher: DataFetcher;
    commentsManager: CommentsManager;
    appStateManager: AppStateManager;
  };
  const { environment, translations } = flowAPI;
  const isViewer = environment.isViewer || environment.isSSR;
  experimentsWrapper.setExperiments(flowAPI.experiments.all());
  const { removeItems } = collectionsApi(flowAPI.httpClient);

  const isSSR = flowAPI.environment.isSSR;
  const renderArtStore = !isSSR;
  const biLogger = flowAPI.bi;
  const { baseUrls = {}, appDefinitionId, instance, instanceId } = appParams;
  const baseUrl = getBaseUrl(isViewer);

  return {
    pageReady: async () => {
      let galleryType = getGalleryType(wixCodeApi.site.currentPage);

      const albumData = await dataFetcher.getAlbumData({
        baseUrl,
      });
      if (!albumData) {
        return;
      }

      if (environment.isEditor) {
        galleryType = GALLERY_TYPES.DEMO;
      }

      const isSetsGallery = galleryType === GALLERY_TYPES.SETS;
      if (!Object.values(GALLERY_TYPES).find((type) => type === galleryType)) {
        return {
          pageReady: () => {
            setProps({});
          },
        };
      }

      platformAPIs.pubSub.subscribe(
        'changeGalleryVisibility',
        ({ data }: any) => {
          setProps({ visible: data.visible });
        },
        false,
      );

      const getAuthHeader = () =>
        wixCodeApi.site.getAppToken
          ? wixCodeApi.site.getAppToken(appDefinitionId)
          : instance;

      const {
        items,
        galleryId,
        styleParams,
        locked,
        userStatus,
        totalItemsCount,
      } = await getInitialData({
        dataFetcher,
        wixCodeApi,
        environment: flowAPI.environment,
        galleryType,
        baseUrl,
        albumData,
        experiments: flowAPI.experiments,
      });

      const SSRWorkerLog: any = [];
      let fetcherType;
      if (galleryType === GALLERY_TYPES.SET) {
        fetcherType = SetItemsFetcher;
      } else {
        fetcherType = CollectionItemsFetcher;
      }

      const fetcher = new fetcherType({
        context,
        controllerConfig: config,
        reportBiLog: {},
        SSRWorkerLog,
        galleryId,
        items: isSetsGallery ? [] : items,
        dateCreated: albumData?.settings?.dateCreated,
        albumId: albumData?.settings?.id,
        albumData,
      });

      appStateManager.registerWidget({
        name: 'gallery',
        setProps,
      });

      let setsToPassToWidget: AlbumSet[] | undefined;
      if (environment.isPreview && galleryId === 'yoursitename') {
        setsToPassToWidget = albumData?.sets.gallery;
      }
      const appDefId = renderArtStore
        ? ART_STORE.ARTSTORE_APP_DEFINITION_ID
        : PRO_GALLERY.PG_APP_DEFINITION_ID;
      const commonViewerScript = new CommonViewerScript({
        context,
        controllerConfig: {
          ...config,
          appDefinitionId: appDefId,
          avoidGettingItemsFromPGServer: isSetsGallery,
        },
        APP_DEFINITION_ID: appDefId,
        GALLERY_WIDGET_ID: renderArtStore
          ? ART_STORE.GALLERY_WIDGET_ID
          : PRO_GALLERY.GALLERY_WIDGET_ID,
        isArtStore: renderArtStore,
        fetcher,
        SSRWorkerLog,
        withWarmupData: false,
      });

      // Overriden so that pg wont update the gallery height upon switching layouts
      // We are not in a real editor and we determine the height by ourself in the template's editor
      commonViewerScript.getSetHeightFunc = () => {
        return () => {};
      };

      const { consentPolicy, storeApi } = renderArtStore
        ? initStoreManager({
            wixCodeApi,
            storeManager,
            setProps,
            compId,
          })
        : { consentPolicy: undefined, storeApi: undefined };

      albumData &&
        galleryWixCodeApiManager.init({
          commonViewerScript,
          setProps,
          storeManager,
          styleParams,
          type,
          compId,
          consentPolicy,
          albumData,
          isInBuilder: flowAPI.environment.isPreview,
          isMobile: flowAPI.environment.isMobile,
        });

      const { setStyleParams, setSettings, setItems } =
        galleryWixCodeApiManager.galleryWixCodeActions;
      isSetsGallery && items && setItems(items);
      const albumsSettings: AlbumSettingsExtended = {
        ...albumData.settings,
        galleryId,
        albumId: albumData.settings.id,
        waterkmark: albumData.settings.watermark, // Remove when stop using watermark
        styleParams,
        freeArtStore: !albumData.settings.isStoreAlbum,
      };

      initProGalleryStore(commonViewerScript, albumsSettings, setSettings);
      const triggerJoinModal = async () => {
        platformAPIs.pubSub.publish('triggerJoinModal', {}, true);
      };
      const triggerSignUpModal = async () => {
        platformAPIs.pubSub.publish('triggerSignUpModal', {}, true);
      };

      const removeItem = async (itemId: string) => {
        const currUserStatus = appStateManager.getUserStatus();
        if (isNotLoggedIn(currUserStatus)) {
          triggerSignUpModal();
        } else {
          if (isNotListMember(currUserStatus)) {
            triggerJoinModal();
          } else {
            await removeItems(galleryId, [itemId]);
            await appStateManager.refetchCollectionItems();
          }
        }
      };

      // used to update userId after login
      wixCodeApi.user.onLogin(() => {
        setProps({
          user: {
            role: wixCodeApi.user.currentUser?.role,
            id: wixCodeApi.user.currentUser?.id,
          },
        });
      });

      try {
        const renderGallery = (viewPortState: any) => {
          let notInView = false;
          if (viewPortState && !viewPortState.in) {
            // notInView can be true only in SSR.
            // tryToReportAppLoaded for this case will be called later here in "if (commonViewerScript.isSSR())"
            notInView = true;
          }
          commonViewerScript.loadInitialBlueprint();
          commonViewerScript.loadDirectFullscreen();
          setStyleParams(styleParams);
          return getInitPromise(
            commonViewerScript,
            storeManager,
            albumsSettings,
          )
            .catch((e) => {
              commonViewerScript.sentryReport(e);
              console.error('Waiting for promises failed', e);
            })
            .then(
              ({
                connectedProviders,
                blueprint,
                additionalProviderParams,
              }: any) => {
                if (!isSSR) {
                  if (galleryType === GALLERY_TYPES.LIST) {
                    commentsManager.initCommentsController({
                      controllerConfig: flowAPI.controllerConfig,
                      albumData,
                      biLogger,
                      collectionId: galleryId,
                      wixCodeApi,
                      appStateManager,
                      commentsManager,
                      isMainController: false,
                      httpClient: flowAPI.httpClient,
                    });
                  } else {
                    if (
                      albumData.settings?.loveButtonAction ===
                      LOVE_BUTTON_ACTION.FAVORITE_LISTS
                    ) {
                      const loveService = new LoveService({
                        experiments: flowAPI.experiments,
                        albumData,
                        httpClient: flowAPI.httpClient,
                        setWidgetsProps: appStateManager.setWidgetsProps,
                        galleryId,
                        appStateManager,
                        t: translations.t,
                        log: (func: any, additionalBiParams: any) =>
                          biLogger?.report(
                            func({
                              albumId: albumData?.settings?.id,
                              instanceId,
                              visitorId: wixCodeApi.user.currentUser.id,
                              role: wixCodeApi.user.currentUser.role,
                              ...additionalBiParams,
                            }),
                          ),
                        instance: getAuthHeader(),
                        getAuthHeader,
                        metaSiteId: albumData.settings.metaSiteId,
                        isViewer,
                        firstItem: items?.[0] as MediaItem,
                      });
                      if (appStateManager.state.user.loggedIn) {
                        loveService.init();
                      } else {
                        wixCodeApi.user.onLogin(() => {
                          loveService.init();
                        });
                      }
                    }
                  }
                }

                // All the other props are propagating to gallery here:
                let initialProps = {
                  ...commonViewerScript.getCommonGalleryProps(renderArtStore),
                  storeApi,
                  onItemClicked: commonViewerScript.getOnItemClickedFunc(
                    galleryWixCodeApiManager.galleryWixCodeApi,
                  ),
                  onLinkNavigation:
                    commonViewerScript.getOnLinkNavigationFunc(),
                  notInView,
                  visible: true,
                  galleryType,
                  watermarkData: albumsSettings.waterkmark,
                  totalItemsCount,
                  ...blueprint,
                  directFullscreenItem:
                    commonViewerScript.getDirectFullscreenItem(),
                  setMetaTags: commonViewerScript.getSetMetaTagsFunc(),
                  directFullscreenMockBlueprint:
                    commonViewerScript.getDirectFullscreenMockBlueprint(),
                  galleryWixCodeApi:
                    galleryWixCodeApiManager.galleryWixCodeActions,
                  galleryId,
                  locked,
                  removeItem,
                  userStatus,
                  // membersStatus,
                  navigateToMember: (memberId: string, memberSlug: string) => {
                    wixCodeApi.site
                      .getPublicAPI(MEMBERS_AREA)
                      .then((api: any) =>
                        api.navigateToMember({ memberId, memberSlug }),
                      );
                  },
                  triggerJoinModal,
                  actions: getActions({
                    getAuthHeader,
                    albumData,
                    appStateManager,
                    setProps,
                    errorMonitor: flowAPI.errorMonitor,
                    httpClient: flowAPI.httpClient,
                  }),
                  getAuthHeader,
                  user: {
                    role: wixCodeApi.user.currentUser?.role,
                    id: wixCodeApi.user.currentUser?.id,
                  },
                  loveButtonAction: albumData.settings?.loveButtonAction,
                  appDefinitionId: appDefId,
                  sets: setsToPassToWidget,
                  experiments: flowAPI.experiments.all(),
                  appInstance: instance,
                  albumData,
                };
                if (renderArtStore) {
                  initialProps = {
                    ...initialProps,
                    ...storeManager.getInitalProps(
                      { compId, setProps, type, consentPolicy },
                      connectedProviders,
                      ALBUMS.ALBUMS_APP_DEF_ID,
                      additionalProviderParams,
                    ),

                    navigator: createNavigator(wixCodeApi),
                    loggedIn: appStateManager.state.user.loggedIn,
                  };
                }
                setProps(initialProps);

                return commonViewerScript.getWarmupData();
              },
            );
        };
        return commonViewerScript
          .getViewPortPromise()
          .then(renderGallery)
          .catch((error: any) => {
            console.error(error);
            commonViewerScript.sentryReport({
              errorMessage: 'viewportPromise rejected!',
              error,
            });
          });
      } catch (e: any) {
        console.error(e);
      }
    },
    exports: () => {},
  };
};

export default createController;
