import { GalleryLayout, SETS_GALLERY_ID } from '../../../consts/galleryConsts';
import {
  getDefaultStyleParamsByLayout,
  getSetsDefaultStyleParams,
  getEditorDefaultGalleryStyles,
  getDefaultSilderStyleParams,
} from '../../../consts/defautStyleParams';
import { getImageItemUrl } from '../../../services/imageClientApiService';
import { GALLERY_TYPES } from '../../../common/helpers/galleryTypes';
import DataFetcher from '../../../viewerScriptHelpers/DataFetcher';
import {
  serverItemsToProGallery,
  proGalleryItemsToWixCode,
} from '@wix/pro-gallery-items-formatter';

type Unpromise<T extends Promise<any>> = T extends Promise<infer U> ? U : never;
export async function getInitialData({
  wixCodeApi,
  dataFetcher,
  environment,
  galleryType,
  baseUrl,
  albumData,
  experiments,
}: {
  wixCodeApi: any;
  dataFetcher: DataFetcher;
  environment: any;
  galleryType: string;
  baseUrl: string;
  albumData: AlbumData;
  experiments: any;
}) {
  let items: MediaItem[] | WixCodeItem[] | undefined, galleryId, styleParams;
  let locked;
  let userStatus;

  const { settings, sets } = albumData;
  const currentAlbumStyleParams = settings.styleParams;

  const { path, prefix } = wixCodeApi.location;
  const { length } = path;

  switch (galleryType) {
    case GALLERY_TYPES.LIST: {
      const id = length > 1 && path[1];
      const [getCollectionResponse, collectionItems] = await Promise.all([
        dataFetcher.getCollectionData({
          collectionId: id,
          baseUrl,
        }),
        dataFetcher.getCollectionItems({
          collectionId: id,
          baseUrl,
        }),
      ]);

      userStatus = getCollectionResponse.memberStatus;
      const collection = getCollectionResponse.mediaCollection;
      collection.items = collectionItems;
      galleryId = id;
      items = collection.items;
      const defaultStyleParams = albumData.settings.styleParams;
      const shouldRenderSliderLayout =
        currentAlbumStyleParams.galleryLayout === 5;

      styleParams = {
        ...defaultStyleParams,
        allowDownload: currentAlbumStyleParams?.allowDownload,
        allowSocial: currentAlbumStyleParams?.allowSocial,
        loveButton: false,
        hoveringBehaviour: 'NO_CHANGE',
        overlayBackground: 'rgba(0,0,0,0)',
      };

      if (shouldRenderSliderLayout) {
        styleParams = { ...styleParams, ...getDefaultSilderStyleParams() };
      }
      locked = collection.locked;
      break;
    }
    case GALLERY_TYPES.SET: {
      const id = prefix === 'set' && length > 0 && path[length - 1];
      const currentSet = !id
        ? sets?.gallery[0]
        : sets?.gallery?.find((set: AlbumSet) => set.id === id) || {
            items: [],
            id: '',
          };

      galleryId = currentSet?.id || id;
      items = currentSet?.items;
      const fullscreenStyleParams = {
        defaultShowInfoExpand: environment.isMobile ? false : true,
      };
      styleParams = {
        ...currentAlbumStyleParams,
        ...fullscreenStyleParams,
        isStoreGallery: true,
      };
      break;
    }
    case GALLERY_TYPES.SETS: {
      const setsData = sets?.gallery?.flatMap((gallery) => {
        return gallery.items && gallery.items?.at(0)
          ? [
              {
                id: gallery.id,
                cover: gallery.items[0],
                name: gallery.name,
              },
            ]
          : [];
      });

      items = proGalleryItemsToWixCode(
        serverItemsToProGallery(setsData.map((x) => x.cover)),
      );
      (items as WixCodeItem[]).forEach((item: WixCodeItem, idx: number) => {
        const setData = setsData[idx];
        item.link = '/set/' + setData.id;
        item.title = setData.name;
      });

      styleParams = getSetsDefaultStyleParams();
      styleParams.itemClick = 'link';
      styleParams.behaviourParams.item.clickAction = 'ACTION';
      galleryId = settings?.id || SETS_GALLERY_ID;
      break;
    }
    case GALLERY_TYPES.DEMO: {
      const currentSet = sets?.gallery[0];
      galleryId = currentSet?.id;
      items = currentSet?.items;
      styleParams = getEditorDefaultGalleryStyles();
      break;
    }
    default:
      galleryId = 'albums-default-gallery-id';
      items = [];
      break;
  }

  return {
    items,
    albumData,
    galleryId,
    styleParams,
    galleryType,
    locked,
    userStatus,
    totalItemsCount: items ? items.length : 0,
    // membersStatus,
  };
}
