import { serverItemsToProGallery } from '@wix/pro-gallery-items-formatter';
import { FetcherProps, BaseItemsFetcher } from './BaseItemsFetcher';

export class CollectionItemsFetcher extends BaseItemsFetcher {
  async getItemByIdFromServer(itemId: string) {
    const { instance } = this.context;
    const itemUrl = `${this.baseUrl}/_api/media-collections-service/v1/mediacollections/${this.galleryId}/items/${itemId}`;
    try {
      const response = await fetch(itemUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: instance,
          'x-wix-linguist': this.getMultiLangHeader(),
        },
      });
      const { item } = await response.json();
      return serverItemsToProGallery([item])[0];
    } catch (e: any) {
      console.error('Could not get item by id from the server', e);
      return {
        item: 'some error when requesting from server' + itemUrl + e.message,
      };
    }
  }
}
