import { Status, ActionType } from '../consts/collectionsConsts';
import collectionsApi from '../api/collections-api';
import _ from 'lodash';
import type { AddItem } from '@wix/ambassador-collections-v1-collection/types';
import { updateCollectionItemsOnServer } from '../common/helpers/itemsUpdate';
import type AppStateManager from './AppStateManager';
import type { IHttpClient } from '@wix/yoshi-flow-editor';
import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';

type ActionsManagerProps = {
  getAuthHeader: () => string;
  albumData: AlbumData;
  setProps: (props: any) => void;
  appStateManager: AppStateManager;
  errorMonitor: ErrorMonitor;
  httpClient: IHttpClient;
};

export const getActions = ({
  getAuthHeader,
  albumData,
  appStateManager,
  setProps,
  errorMonitor,
  httpClient,
}: ActionsManagerProps) => {
  const {
    leaveCollection,
    deleteCollection,
    addItems,
    removeItems,
    removeFromCollection,
    updateCollectionOnServer,
    getItemsZipUrl,
    createCollectionOnServer,
  } = collectionsApi(httpClient);

  const isDownloadAllowed = albumData?.settings?.styleParams?.allowDownload;

  const actions = {
    [ActionType.CREATE]: async ({
      name,
      privacySettings,
      description,
    }: {
      name: string;
      privacySettings?: PrivacySettings;
      description?: string;
    }) => {
      const { mediaCollectionId } = await createCollectionOnServer({
        name,
        privacySettings,
        description,
      });
      // appStateManager.setitemCollectionsMap([
      //   ...appStateManager.getitemCollectionsMap(),
      //   {
      //     mediaCollectionId: mediaCollectionId || 'id',
      //     name: name || 'name',
      //     role: Status.MEMBER,
      //     isItemInCollection: false,
      //     isLocked: false,
      //   },
      // ]);
      appStateManager.fetchCollections();
      return mediaCollectionId;
    },
    [ActionType.JOIN]: async (parsedData?: {
      showInfoModal?: boolean;
      origin?: string;
    }) => {
      await appStateManager.onJoinCollection(parsedData?.origin);
    },
    [ActionType.DELETE]: async () => {
      await deleteCollection(appStateManager.getCollection().id);
    },
    [ActionType.LEAVE]: async () => {
      await leaveCollection(appStateManager.getCollection().id);
      appStateManager.setUserStatus({ status: Status.NOT_MEMBER });
      appStateManager.fetchMembers();
    },
    [ActionType.UPDATE]: async (parsedData?: {
      modifiedCollection: MediaCollection;
      fieldMask: string[];
    }) => {
      const modifiedCollection = parsedData?.modifiedCollection;
      const fieldMask = parsedData?.fieldMask;
      if (modifiedCollection && fieldMask) {
        await updateCollectionOnServer({
          collection: modifiedCollection,
          fieldMask,
        });
        appStateManager.updateCollectionData(modifiedCollection);
      } else {
        console.warn('No collection or no field mask');
      }
    },
    [ActionType.ADD_ITEMS]: async (parsedData?: {
      items: AddItem[];
      collectionId?: string;
    }) => {
      const items = parsedData?.items;
      const id = parsedData?.collectionId || appStateManager.getCollection().id;
      items && (await addItems(id, items));
    },
    [ActionType.REMOVE_ITEMS]: async (parsedData?: {
      items: string[];
      collectionId?: string;
    }) => {
      const items = parsedData?.items;
      const id = parsedData?.collectionId || appStateManager.getCollection().id;
      items && (await removeItems(id, items));
    },
    [ActionType.UPDATE_ITEMS]: async (parsedData?: {
      itemsSelection: ItemsSelectionMap;
    }) => {
      const itemsSelection = parsedData?.itemsSelection;
      if (itemsSelection) {
        const itemsSelected = Object.values(itemsSelection)
          .filter((itemSelection) => itemSelection.selected)
          .map((itemSelection) => itemSelection.item);

        await updateCollectionItemsOnServer(
          appStateManager.getCollection().id,
          itemsSelection,
          httpClient,
        );
        appStateManager.updateCollectionItems(itemsSelected);
      }
    },
    [ActionType.LOG_IN]: async (parsedData?: {
      showInfoModal?: boolean;
      origin?: string;
    }) => {
      if (parsedData?.showInfoModal) {
        await appStateManager.login(parsedData?.origin);
      } else {
        await appStateManager.promptLogin({ modal: true });
        appStateManager.setUserLoggedIn(true);
      }
    },
    [ActionType.REMOVE_MEMBER]: async (parsedData?: { memberId: string }) => {
      const memberId = parsedData?.memberId;
      if (memberId) {
        await removeFromCollection(
          appStateManager.getCollection().id,
          memberId,
        );
        appStateManager.fetchMembers();
      }
    },
    ...(isDownloadAllowed && {
      [ActionType.DOWNLOAD]: async (parsedData?: { itemIds: string[] }) => {
        const itemIds = parsedData?.itemIds;
        const url = await getItemsZipUrl(
          appStateManager.getCollection().id,
          albumData.settings.motherSiteMetaSiteId,
          albumData.settings.id,
          itemIds,
        );
        setProps({
          actionCallbacks: [
            { callbackName: 'openWindow', args: [url] },
          ] as Callbacks,
        });
      },
    }),
  } as {
    [key in keyof typeof ActionType]: (params?: string) => any;
  };

  const actionsWithResponse = _.mapValues(actions, (action, actionName) => {
    return async (data?: string) => {
      try {
        const parsedData = data && JSON.parse(data);
        const resolvedData = await action(parsedData);
        setProps({
          promiseResult: `${actionName}_resolved_${Date.now()}`,
          promiseResultData: resolvedData,
        });
      } catch (e: any) {
        setProps({ promiseResult: `${actionName}_rejected_${Date.now()}` });
        console.error(e);
        const wixRequestId = _.get(
          e,
          'response.headers.x-wix-request-id',
          'MISSING-WIX-REQUEST-ID',
        );
        errorMonitor &&
          errorMonitor.captureException(e, {
            contexts: {
              debugInfo: {
                requestId: wixRequestId,
              },
            },
          });
      }
    };
  });

  return actionsWithResponse;
};

export type Actions = ReturnType<typeof getActions>;
