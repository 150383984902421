import { serverItemsToProGallery } from '@wix/pro-gallery-items-formatter';
import { FetcherProps, BaseItemsFetcher } from './BaseItemsFetcher';

export class SetItemsFetcher extends BaseItemsFetcher {
  async getItemByIdFromServer(itemId: string) {
    const { instance } = this.context;
    const itemUrl = `https://www.wix.com/_api/albums-node-server/getSignedGalleryItem?galleryId=${this.galleryId}&albumId=${this.albumId}&itemId=${itemId}`;
    try {
      const result = await fetch(itemUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: instance,
          'x-wix-linguist': this.getMultiLangHeader(),
        },
      });

      const item = await result.json();
      return serverItemsToProGallery([item])[0];
    } catch (e: any) {
      console.error('Could not get item by id from the server', e);
      return {
        item: 'some error when requesting from server' + itemUrl + e.message,
      };
    }
  }
}
