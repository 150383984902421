import collectionsApi from '../../api/collections-api';
import { Actions } from '../../components/Collection/types';
import { clickSaveCollection } from '@wix/bi-logger-photo-ugc/v2';
import { IHttpClient } from '@wix/yoshi-flow-editor';

const getSelectionChangesArrays = (itemsSelection: ItemsSelectionMap) => {
  const addedItems: ItemToAdd[] = [];
  const removedItems: string[] = [];

  Object.values(itemsSelection).forEach((itemSelection: ItemSelection) => {
    if (itemSelection.selected !== itemSelection.originallySelected) {
      const { item, galleryId, orderIndex } = itemSelection;
      const itemId = item.id;
      itemSelection.selected
        ? addedItems.push({ itemId, galleryId, orderIndex })
        : removedItems.push(itemId);
    }
  });

  return { addedItems, removedItems };
};

export const updateCollectionItemsOnServer = async (
  collectionId: string,
  itemsSelection: ItemsSelectionMap,
  httpClient: IHttpClient,
) => {
  const { addItems, removeItems } = collectionsApi(httpClient);
  const { addedItems, removedItems } =
    getSelectionChangesArrays(itemsSelection);

  addedItems.length > 0 && (await addItems(collectionId, addedItems));
  removedItems.length > 0 && (await removeItems(collectionId, removedItems));
};

export const updateItems = async (
  itemsSelection: ItemsSelectionMap,
  actions: Actions,
  biLogger: {
    log: any;
  },
  t: (key: string) => string,
) => {
  await actions.UPDATE_ITEMS(JSON.stringify({ itemsSelection }));
  const itemsSelected = Object.values(itemsSelection)
    .filter((itemSelection) => itemSelection.selected)
    .map((itemSelection) => itemSelection.item);
  biLogger.log(clickSaveCollection, {
    amountOfItemsSelected: itemsSelected.length,
    amountOfItems: Object.values(itemsSelection).length,
    origin: 'media selection',
  });
  return itemsSelected;
};
