import { Utils } from '@wix/common-pro-gallery-worker-wrapper';
import { serverItemsToProGallery } from '@wix/pro-gallery-items-formatter';

export interface FetcherProps {
  context: any;
  controllerConfig: any;
  reportBiLog: any;
  SSRWorkerLog: any;
  albumData?: AlbumData;
  galleryId: string;
  dateCreated?: number;
  items?: any[];
  albumId?: string;
}

export class BaseItemsFetcher {
  SSRWorkerLog: any;
  ITEMS_BATCH_SIZE: number;
  controllerConfig: any;
  wixCodeApi: any;
  viewMode: any;
  context: any;
  baseUrl: string;
  compId: string;
  externalId: string;
  instance: string;
  instanceId: any;
  reportBiLog: any;
  galleryId: string;
  settings: any;
  dateCreated?: number;
  items?: any[];
  albumId?: string;
  albumData: any;

  constructor(props: FetcherProps) {
    const {
      context,
      controllerConfig,
      reportBiLog,
      SSRWorkerLog,
      galleryId,
      items,
      dateCreated,
      albumId,
      albumData,
    } = props;
    this.SSRWorkerLog = SSRWorkerLog;
    this.ITEMS_BATCH_SIZE = 50;
    this.controllerConfig = controllerConfig;
    this.wixCodeApi = controllerConfig.wixCodeApi;
    this.viewMode = Utils.parseViewMode(this.wixCodeApi.window.viewMode);
    this.context = context.getContext();
    this.baseUrl = Utils.getBaseUrl(this.wixCodeApi, this.viewMode);
    this.compId = controllerConfig.compId;
    this.externalId =
      controllerConfig.config.externalId ||
      '00000000-0000-0000-0000-000000000000'; // externalId of a new site before publish in Preview is "", so we protect here undefined and ""
    this.instance = controllerConfig.appParams.instance;
    this.instanceId = controllerConfig.appParams.instanceId;
    this.reportBiLog = reportBiLog;
    this.galleryId = galleryId;
    this.dateCreated = dateCreated;
    this.items = items;
    this.albumId = albumId;
    this.albumData = albumData;
  }

  isSSR() {
    return this.wixCodeApi.window.rendering.env === 'backend';
  }

  async fetchGalleryId() {
    return;
  }

  getMultiLangFields() {
    const currentShortLang =
      this.wixCodeApi.window.multilingual.currentLanguage;
    const currentLang =
      this.wixCodeApi.window.multilingual.siteLanguages.filter(
        (lang: any) => lang.languageCode === currentShortLang,
      );
    if (currentLang.length > 0) {
      return {
        isPrimaryLanguage: currentLang[0].isPrimaryLanguage,
        lang: currentShortLang,
        locale: currentLang[0].locale,
      };
    }

    return null;
  }

  getMultiLangHeader() {
    const fields = this.getMultiLangFields();
    const header = fields
      ? `${fields.lang}|${
          fields.locale
        }|${fields.isPrimaryLanguage.toString()}|${this.instanceId}`
      : '';
    this.SSRWorkerLog.push('Multilingual header: ' + header);
    return header;
  }

  getLangQuery() {
    const fields = this.getMultiLangFields();
    const query = fields ? `${fields.lang}${fields.locale}` : '';
    return query;
  }

  async getGalleryDataFromServer(
    this: any,
    {
      from,
      batchSize = this.ITEMS_BATCH_SIZE,
    }: { from: number; batchSize?: number },
  ) {
    const gallery = this.albumData?.sets.gallery.find(
      (currGallery: any) => currGallery.id === this.galleryId,
    );
    const items = this.items.slice(from, from + batchSize);
    return {
      totalItemsCount: gallery?.totalItemsCount,
      settings:
        this.albumData?.settings && JSON.stringify(this.albumData?.settings),
      items: serverItemsToProGallery(items),
    };
  }

  setSettings(settings: any) {
    const parseSettings = (_settings: any) => {
      if (typeof _settings === 'string') {
        try {
          return JSON.parse(_settings);
        } catch (e: any) {
          console.error('Failed parse settting', e);
          return _settings;
        }
      }
      return _settings;
    };
    const prevSettings = parseSettings(this.settings);
    const nextSettings = parseSettings(settings);
    this.settings = { ...prevSettings, ...nextSettings };
  }
}
